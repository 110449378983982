export const ORDER_STORAGE_KEY = "dpd-order";
export const ORDER_STORAGE_TIME_KEY = "dpd-order-time";
export const ORDER_STORAGE_TIMEOUT = 5 * 60 * 1000;

export const ORDER_PREFILL_QUERY_PARAM = "data";

export enum OrderStep {
  PackageInfo = "PackageInfo",
  ShipmentDetails = "ShipmentDetails",
  SenderDetails = "SenderDetails",
  ReceiverDetails = "ReceiverDetails",
  Summary = "Summary",
  Success = "Success",
}

export enum DeliveryMethod {
  PudoToPudo = "pudo_to_pudo",
  PudoToDoor = "pudo_to_door",
  DoorToPudo = "door_to_pudo",
  DoorToDoor = "door_to_door",
}

export const ORDER_MESSAGE_ZONE = "order";

export const ORDER_FIELDS_MAX_LENGTHS = {
  name: 35,
  default: 200,
  street: 35,
  email: 100,
  comment: 35,
  senderComment: 255,
  senderCollectionRequest: 40,
  city: 35,
  houseNo: 8,
  flatNo: 8,
  zip: 8,
  long: 50
};

export enum OrderFields {
  CURRENT_STEP = "currentStep",
  IS_DOMESTIC = "isDomestic",
  IS_INTRA_EUROPE = "isIntraEurope",
  IS_FROM_ORIGIN = "isFromOrigin",
  PROMO_CODE = "promoCode",
  OFFER_GROUP_ID = "offerGroupId",
  SHIPMENT_ID = "shipmentId",
  SHIPMENT_SIGNED_IN = "shipmentSignedIn",
  AFFILIATE_ID = "affiliateId",
  AFFILIATE_RETURN_URL = "affiliateReturnUrl",

  STARTING_COUNTRY = "startingCountry",
  STARTING_COUNTRY_NAME = "startingCountryName",
  DESTINATION_COUNTRY = "destinationCountry",
  DESTINATION_COUNTRY_NAME = "destinationCountryName",
  PACKAGE_SIZE = "packageSize",
  DELIVERY_METHOD = "deliveryMethod",

  SENDER_NAME = "senderName",
  SENDER_PHONE_NUMBER = "senderPhoneNumber",
  SENDER_EMAIL = "senderEmail",
  RETURN_LOCKER = "returnLocker",
  RETURN_LOCKER_NAME = "returnLockerName",
  SENDER_STREET = "senderStreet",
  SENDER_HOUSE_NO = "senderHouseNo",
  SENDER_FLAT_NO = "senderFlatNo",
  SENDER_CITY = "senderCity",
  SENDER_ZIP_CODE = "senderZipCode",
  SENDER_COMMENTS = "senderComments",

  LABEL_PRINT = "labelPrint",
  PICKUP_DATE = "pickupDate",
  PICKUP_TIME_FROM = "pickupTimeFrom",
  PICKUP_TIME_TILL = "pickupTimeTill",
  SENDER_AS_CONTACT_PERSON = "senderAsContactPerson",
  CONTACT_PERSON_NAME = "contactPersonName",
  CONTACT_PERSON_PHONE_NUMBER = "contactPersonPhoneNumber",
  CONTACT_PERSON_EMAIL = "contactPersonEmail",

  RECEIVER_NAME = "receiverName",
  RECEIVER_PHONE_NUMBER = "receiverPhoneNumber",
  RECEIVER_EMAIL = "receiverEmail",
  RECEIVER_LOCKER = "receiverLocker",
  RECEIVER_LOCKER_NAME = "receiverLockerName",
  RECEIVER_STREET = "receiverStreet",
  RECEIVER_HOUSE_NO = "receiverHouseNo",
  RECEIVER_FLAT_NO = "receiverFlatNo",
  RECEIVER_CITY = "receiverCity",
  RECEIVER_ZIP_CODE = "receiverZipCode",
  RECEIVER_COMMENTS = "receiverComments",
  RECEIVER_ADDRESS_ID = "receiverAddressId",

  HAS_NEW_ADDRESS = "hasNewAddress",
  NEW_ADDRESS_NAME = "newAddressName",
  NEW_ADDRESS_PHONE = "newAddressPhone",
  NEW_ADDRESS_EMAIL = "newAddressEmail",
  NEW_ADDRESS_LOCKER = "newAddressLocker",
  NEW_ADDRESS_LOCKER_NAME = "newAddressLockerName",
  NEW_ADDRESS_STREET = "newAddressStreet",
  NEW_ADDRESS_HOUSE_NO = "newAddressHouseNo",
  NEW_ADDRESS_FLAT_NO = "newAddressFlatNo",
  NEW_ADDRESS_CITY = "newAddressCity",
  NEW_ADDRESS_ZIP_CODE = "newAddressZipCode",

  INVOICE_TYPE = "invoiceType",
  INVOICE_NAME = "invoiceName",
  INVOICE_EMAIL = "invoiceEmail",
  INVOICE_COMPANY_NAME = "invoiceCompanyName",
  INVOICE_COMPANY_EMAIL = "invoiceCompanyEmail",
  INVOICE_REGISTRY_CODE = "invoiceRegistryCode",
  INVOICE_VAT_NUMBER = "invoiceVatNumber",
  INVOICE_REGISTRATION_ADDRESS = "invoiceRegistrationAddress",

  TERMS_AGREEMENT = "termsAgreement",

  PAYMENT_METHOD = "paymentMethod",

  PRICE = "price",
  ORIGINAL_PRICE = "originalPrice",
}

export const SHIPMENT_STEP_DELIVERY_METHODS = [DeliveryMethod.PudoToPudo, DeliveryMethod.PudoToDoor];

export const COUNTRY_CODE_LT = "LT";
export const COUNTRY_CODE_LV = "LV";
export const COUNTRY_CODE_EE = "EE";

export const FIXED_DESCRIPTION_PACKAGE_SIZE = "XL";

export enum InvoiceType {
  PRIVATE = "private",
  COMPANY = "company",
}

export const ORDER_LABEL_PAPER_SIZE = "A4";

export enum LabelPrint {
  COURIER = "courier",
  PRINT = "print",
}

export enum InitialOrderDataPart {
  PREFILL_VALUES = "prefillValues",
  PICKUP_TIMEFRAMES = "pickupTimeframes",
}

export const EMPTY_PAYMENT_METHOD = "save-for-later";
